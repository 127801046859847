<template>
    <div class="container" id="EnrollAuditList">
        <el-row class="listHeader">
            <el-col class="icon">
                <div>
                    <i class="el-icon-trophy" style="font-size:32px"></i>
                </div>
                <div>审核</div>
            </el-col>
            <el-col>
            </el-col>
        </el-row>
        <el-row>
            <el-form>
                <el-form-item label="状态筛选：">
                    <el-button v-for="(item,index) in statusList" :key="index"
                        size="small"
                        :plain="item.id == status ? false : true"
                        type="primary"
                        @click="status = item.id"
                    >{{item.text}}</el-button>
                </el-form-item>
            </el-form>
        </el-row>
        <el-row style="height:calc(100% - 180px)">
            <el-table
                v-loading="loading"
                element-loading-text="数据加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
                :data="list"
                border
                style="width: 100%"
                height="100%"
                size="mini"
            >
                <el-table-column prop="LeagueName" label="赛事名称"></el-table-column>
                <el-table-column label="报名时间">
                    <template slot-scope="scope">
                        <span >
                            {{scope.row.EnrollBeginTime | dateFormat('yyyy-MM-dd')}}
                            ~
                            <template v-if="scope.row.EnrollEndTime">
                            {{scope.row.EnrollEndTime | dateFormat('yyyy-MM-dd')}}
                            </template>
                            <template v-else>无限期</template>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="状态" width="100">
                    <template slot-scope="scope">
                        <span>{{[
                            '报名中',
                            '报名结束',
                            '公示中',
                            '公示结束',
                            '修改中',
                            '修改结束',
                            '已完成',
                        ][scope.row.EnrollStatus - 1]}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="结束时间">
                    <template slot-scope="scope">
                        <template v-if="[3,4].includes(scope.row.EnrollStatus)">
                            <span>{{scope.row.PublicEndTime | dateFormat('yyyy-MM-dd')}}</span>
                            <el-button 
                                type="text"
                                style="margin-left:5px"
                                @click="openOption(1,scope.row)"
                            >修改</el-button>
                        </template>
                        <template v-if="[5,6].includes(scope.row.EnrollStatus)">
                            <span>{{scope.row.UpdateEndTime | dateFormat('yyyy-MM-dd')}}</span>
                            <el-button 
                                type="text"
                                style="margin-left:5px"
                                @click="openOption(2,scope.row)"
                            >修改</el-button>
                        </template>
                        <template v-if="[7].includes(scope.row.EnrollStatus)">
                            <span>{{scope.row.DoneTime | dateFormat('yyyy-MM-dd')}}</span>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column label="线下报名">
                    <template slot-scope="scope">
                        <span v-if="scope.row.OfflineEnrollData && 
                            (scope.row.OfflineEnrollData.TeamsCount > 0 || 
                            scope.row.OfflineEnrollData.SinglesCount > 0 || 
                            scope.row.OfflineEnrollData.DoublesCount > 0)"
                        >{{scope.row.OfflineEnrollData.TeamsCount}}支队伍、
                        {{scope.row.OfflineEnrollData.SinglesCount}}名运动员、
                        {{scope.row.OfflineEnrollData.DoublesCount}}对组合</span>
                        <span v-else>（无）</span>
                        <el-button 
                            type="text"
                            style="margin-left:5px"
                            @click="updateOffline(scope.row)"
                            v-if="scope.row.EnrollStatus != 7"
                        >修改</el-button>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button size="mini" type="primary"
                            v-if="[1,2,3,4,5,6].includes(scope.row.EnrollStatus)"
                            v-href="`/Enroll/EnrollAuditDetail/${scope.row.SubLeagueId}`"
                        >查看/审核</el-button>
                        <el-button size="mini" type="primary"
                            v-if="[7].includes(scope.row.EnrollStatus)"
                            v-href="`/Enroll/EnrollAuditDetail/${scope.row.SubLeagueId}/1`"
                        >查看</el-button>
                        <el-button size="mini" type="success"
                            v-if="[2,6].includes(scope.row.EnrollStatus)"
                            @click="openOption(3,scope.row)"
                        >开始公示</el-button>
                        <el-button size="mini" type="success"
                            v-if="[4].includes(scope.row.EnrollStatus)"
                            @click="openOption(5,scope.row)"
                        >开放修改</el-button>
                        <el-button size="mini" type="success"
                            v-if="[2,4,6].includes(scope.row.EnrollStatus)"
                            @click="openOption(6,scope.row)"
                        >完成报名</el-button>
                        <el-button size="mini" type="success"
                            @click="exportList(scope.row.SubLeagueId, scope.row.LeagueName)"
                        >导出名单</el-button>
                        <el-button size="mini" type="success"
                            @click="exportPhotograph(scope.row.SubLeagueId, scope.row.LeagueName)"
                        >导出肖像照</el-button>
                        <!-- <el-button size="mini" type="success"
                            v-if="[7].includes(scope.row.EnrollStatus)"
                        >制作秩序册</el-button> -->
                        <el-button size="mini" type="warning"
                            v-if="[3].includes(scope.row.EnrollStatus)"
                            @click="openOption(4,scope.row)"
                        >结束公示</el-button>
                        <el-button size="mini" type="warning"
                            v-if="[7].includes(scope.row.EnrollStatus) && !scope.row.NotificationSendOk"
                            @click="afreshSend(scope.row.SubLeagueId)"
                        >重新发送通知</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-row>
        <el-dialog :title="dialogTitle" :visible.sync="dialog.show" :close-on-click-modal="false" width="70%" style="min-width:1000px">
            <el-form ref="form" :model="form" label-width="140px" size="small">
                <template v-if="dialog.type == 1">
                    <el-form-item label="公示开始时间:">{{dialog.info.PublicBeginTime | dateFormat('yyyy-MM-dd HH:mm')}}</el-form-item>
                    <el-form-item label="公示结束时间:"
                        :rules="rules.publicEndTime" 
                        prop="publicEndTime"
                    >
                        <el-date-picker
                            v-model="form.publicEndTime"
                            type="datetime"
                            placeholder="结束时间"
                            format="yyyy-MM-dd HH:mm"
                            value-format="yyyy-MM-ddTHH:mm:ss"
                            :picker-options="publicPickerOptions">
                        </el-date-picker>
                    </el-form-item>
                    <div style="margin-bottom:20px;margin-left:20px">
                        <el-checkbox v-model="form.isOpenUpdate">公示结束后开放修改</el-checkbox>
                    </div>
                    <template v-if="form.isOpenUpdate">
                        <el-form-item label="修改开始时间:">公示结束后开始</el-form-item>
                        <el-form-item label="修改结束时间:" 
                            :rules="rules.updateEndTime"
                            prop="updateEndTime"
                        >
                            <el-date-picker
                                v-model="form.updateEndTime"
                                type="datetime"
                                placeholder="结束时间"
                                format="yyyy-MM-dd HH:mm"
                                value-format="yyyy-MM-ddTHH:mm:ss"
                                :picker-options="updatePickerOptions">
                            </el-date-picker>
                        </el-form-item>
                    </template>
                </template>
                <template v-else-if="dialog.type == 2">
                    <el-form-item label="修改开始时间:">{{dialog.info.PublicEndTime | dateFormat('yyyy-MM-dd HH:mm')}}</el-form-item>
                    <el-form-item label="修改结束时间:" 
                        :rules="rules.updateEndTime"
                        prop="updateEndTime"
                    >
                        <el-date-picker
                            v-model="form.updateEndTime"
                            type="datetime"
                            placeholder="结束时间"
                            format="yyyy-MM-dd HH:mm"
                            value-format="yyyy-MM-ddTHH:mm:ss"
                            :picker-options="updatePickerOptions">
                        </el-date-picker>
                    </el-form-item>
                </template>
                <template v-else-if="dialog.type == 3">
                    <!-- <el-form-item label="公示统计:" >
                        <div class="statistics">
                            <p>共有<span>{{n}}</span>条记录，合计<span>{{n}}</span>支队伍<span>{{n}}</span>名运动员。</p>
                            <el-button 
                                type="text"
                                @click="statistics.show = !statistics.show"
                            >展开统计<i :class="statistics.show ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i></el-button>
                        </div>
                    </el-form-item>
                    <el-form-item v-show="statistics.show">
                        <div class="statistics_content">
                            <div class="statistics_title">
                                <div>
                                    <span>公示统计：</span>
                                    <el-button type="text" @click="refresh()">刷新统计</el-button>
                                </div>
                                <div>
                                    <el-button type="text" v-href="`/Enroll/EnrollAuditDetail/${dialog.info.SubLeagueId}`">有问题？去查看报名详情<i class="el-icon-arrow-down"></i></el-button>
                                </div>
                            </div>
                            <el-table
                                v-loading="statistics.loading"
                                element-loading-text="数据加载中"
                                element-loading-spinner="el-icon-loading"
                                element-loading-background="rgba(0, 0, 0, 0.8)"
                                :data="statistics.list"
                                border
                                style="width: 100%"
                            >
                                <el-table-column prop="LeagueName" label="组别" align="center"></el-table-column>
                                <el-table-column label="审核通过，将公示" align="center">
                                    <template>
                                        <el-button type="text" v-href="`/Enroll/EnrollAuditDetail/${dialog.info.SubLeagueId}?`">6支队伍63名运动员<i class="el-icon-arrow-down"></i></el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </el-form-item> -->
                    <el-form-item label="公示说明:" 
                        :rules="rules.explain" 
                        prop="explain">
                        <Editor v-model="form.explain" :oss-options="this.UploaderOptions"></Editor>
                    </el-form-item>
                    <el-form-item label="举报说明:" 
                        :rules="rules.impeachExplain" 
                        prop="impeachExplain">
                        <Editor v-model="form.impeachExplain" :oss-options="this.UploaderOptions"></Editor>
                    </el-form-item>
                    <el-form-item label="举报电话:" 
                        :rules="rules.impeachTelephone" 
                        prop="impeachTelephone">
                        <el-input v-model.trim="form.impeachTelephone" placeholder="举报电话" style="max-width:200px"></el-input>
                    </el-form-item>
                    <el-form-item label="举报邮箱:" 
                        :rules="rules.impeachMailBox" 
                        prop="impeachMailBox">
                        <el-input v-model.trim="form.impeachMailBox" placeholder="举报邮箱" style="max-width:200px"></el-input>
                    </el-form-item>
                    <el-form-item label="公示时间:" 
                        :rules="rules.publicEndTime" 
                        prop="publicEndTime"
                    > 
                        <span>现在 ~ </span>
                        <el-date-picker
                            v-model="form.publicEndTime"
                            type="datetime"
                            placeholder="结束时间"
                            format="yyyy-MM-dd HH:mm"
                            value-format="yyyy-MM-ddTHH:mm:ss"
                            :picker-options="publicPickerOptions">
                        </el-date-picker>
                    </el-form-item>
                    <div style="margin-bottom:20px;margin-left:20px">
                        <el-checkbox v-model="form.isOpenUpdate">公示结束后开放修改</el-checkbox>
                    </div>
                    <template v-if="form.isOpenUpdate">
                        <el-form-item label="修改时间:" 
                            :rules="rules.updateEndTime"
                            prop="updateEndTime"
                            
                        >
                            <span>公示结束后开始 ~ </span>
                            <el-date-picker
                                v-model="form.updateEndTime"
                                type="datetime"
                                placeholder="结束时间"
                                format="yyyy-MM-dd HH:mm"
                                value-format="yyyy-MM-ddTHH:mm:ss"
                                :picker-options="updatePickerOptions">
                            </el-date-picker>
                        </el-form-item>
                    </template>
                </template>
                <template v-else-if="dialog.type == 4">
                    <el-form-item label="公示结束时间:">{{dialog.info.PublicEndTime | dateFormat('yyyy-MM-dd HH:mm')}} 修改为-> 现在</el-form-item>
                    <div style="margin-bottom:20px;margin-left:20px">
                        <el-checkbox v-model="form.isOpenUpdate">公示结束后开放修改</el-checkbox>
                    </div>
                    <template v-if="form.isOpenUpdate">
                        <el-form-item label="修改开始时间:">现在</el-form-item>
                        <el-form-item label="修改结束时间:" 
                            :rules="rules.updateEndTime"
                            prop="updateEndTime"
                        >
                            <el-date-picker
                                v-model="form.updateEndTime"
                                type="datetime"
                                placeholder="结束时间"
                                format="yyyy-MM-dd HH:mm"
                                value-format="yyyy-MM-ddTHH:mm:ss"
                                :picker-options="updatePickerOptions">
                            </el-date-picker>
                        </el-form-item>
                    </template>
                </template>
                <template v-else-if="dialog.type == 5">
                    <el-form-item label="修改开始时间:">现在</el-form-item>
                    <el-form-item label="修改结束时间:" 
                        :rules="rules.updateEndTime"
                        prop="updateEndTime"
                    >
                        <el-date-picker
                            v-model="form.updateEndTime"
                            type="datetime"
                            placeholder="结束时间"
                            format="yyyy-MM-dd HH:mm"
                            value-format="yyyy-MM-ddTHH:mm:ss"
                            :picker-options="updatePickerOptions">
                        </el-date-picker>
                    </el-form-item>
                </template>
                <template v-else-if="dialog.type == 6">
                    <!-- <el-form-item label="最终参赛者统计:">
                        <div class="statistics">
                            <p>共有<span>{{n}}</span>条记录，合计<span>{{n}}</span>支队伍<span>{{n}}</span>名运动员。</p>
                            <el-button 
                                type="text"
                                @click="statistics.show = !statistics.show"
                            >展开统计<i :class="statistics.show ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i></el-button>
                        </div>
                        <p>提示：不含被举报的运动员。</p>
                    </el-form-item>
                    <el-form-item v-show="statistics.show">
                        <div class="statistics_content">
                            <div class="statistics_title">
                                <div>
                                    <span>最终参赛者统计：</span>
                                    <el-button type="text" @click="refresh()">刷新统计</el-button>
                                </div>
                                <div>
                                    <el-button type="text" v-href="`/Enroll/EnrollAuditDetail/${dialog.info.SubLeagueId}`">有问题？去查看报名详情<i class="el-icon-arrow-down"></i></el-button>
                                </div>
                            </div>
                            <el-table
                                v-loading="statistics.loading"
                                element-loading-text="数据加载中"
                                element-loading-spinner="el-icon-loading"
                                element-loading-background="rgba(0, 0, 0, 0.8)"
                                :data="statistics.list"
                                border
                                style="width: 100%"
                            >
                                <el-table-column prop="LeagueName" label="组别" align="center"></el-table-column>
                                <el-table-column prop="LeagueName" label="审核通过且不是全部被举报，将参赛" align="center">
                                    <template>
                                        <el-button type="text" v-href="`/Enroll/EnrollAuditDetail/${dialog.info.SubLeagueId}?`">6支队伍63名运动员<i class="el-icon-arrow-down"></i></el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </el-form-item> -->
                    <el-form-item label="报名结果通知:">
                        <el-checkbox-group v-model="form.notification">
                            <el-checkbox :label="1">发送报名成功短信通知</el-checkbox>
                        </el-checkbox-group>
                        <!-- <p>{{n}}个代表队＋{{n}}位个人用户，共{{n}}个接收者</p> -->
                    </el-form-item>
                </template>
                <el-form-item label="比赛开始时间:">{{dialog.info.LeagueBeginTime | dateFormat('yyyy-MM-dd HH:mm')}}</el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialog.show = false">取 消</el-button>
                <el-button type="primary" @click="submit">{{dialog.type > 0 ? dialogTexts[dialog.type - 1].submit : ''}}</el-button>
            </div>
        </el-dialog>
        <el-dialog title="线下报名" :visible.sync="offlineShow" :close-on-click-modal="false" :destroy-on-close="true" width="400px" class="offline">
            <el-form ref="offlineForm" :model="offlineForm" size="small" >
                <el-row>
                    <el-form-item label="团体组别:" prop="teamCount" :rules="rules.number" style="margin:0 !important">
                        <el-input type="text" v-model="offlineForm.teamCount" style="width: 100px;"></el-input>支队伍
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="个人组别:" prop="memberCount" :rules="rules.number" style="margin:0 !important">
                        <el-input type="text" v-model="offlineForm.memberCount" style="width: 100px;"></el-input>名运动员
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="双打组别:" prop="doubleCount" :rules="rules.number" style="margin:0 !important">
                        <el-input type="text" v-model="offlineForm.doubleCount" style="width: 100px;"></el-input>对组合
                    </el-form-item>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="offlineShow = false">取 消</el-button>
                <el-button type="primary" @click="submitOffline()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {
    GetLeagueEnrollAuditList,
    StartLeagueEnrollPublic,//开始报名公示
    UpdateLeagueEnrollPublicTime,//修改报名公示的结束时间
    EndLeagueEnrollPublic,//结束报名公示
    OpenLeagueEnrollEditTime,//修改信息修改的结束时间
    CompleteLeagueEnroll,//完成赛事报名
    GetLeagueEnrollPublicSetting,//获取赛事报名设置
    ExportLeagueEnrollTeamNameList,//导出报名参赛者名单
    GetCreateLeagueEnrollInitInfo,
    SendLeaguEnrollResultNotice,
    SaveLeagueOfflineEnrollData
} from '@/api'
import {
    GetLeagueEnrollPortraitPhotoList
} from '@/api/enroll';
import Editor from '@/components/Editor'
import {
    dateFormat,
    numberToChinese
} from '@/assets/js'
import * as XLSX from 'xlsx/xlsx.mjs'
import JSZip from 'jszip'
export default {
    components: { 
        Editor
    },
    data(){
        return {
            isOldLeague:false,
            status:null,
            statusList:[
                {id:null,text:'全部'},
                {id:1,text:'未公示'},
                {id:2,text:'公示中'},
                {id:3,text:'公示结束'},
                {id:4,text:'修改中'},
                {id:5,text:'修改结束'},
                {id:6,text:'已完成'}
            ],
            list:[],
            loading: false,
            dialog:{
                show: false,
                type: 0,
                info: {},
            },
            dialogTexts:[
                {title:'公示结束时间设置',submit:'保 存'},
                {title:'信息修改结束时间设置',submit:'保 存'},
                {title:'现在开始公示',submit:'开始公示'},
                {title:'现在结束公示',submit:'结束公示'},
                {title:'现在开放修改',submit:'确 定'},
                {title:'现在完成报名',submit:'完成报名'}
            ],
            statistics:{
                show: false,
                list: [],
                loading: false
            },
            form: {
                publicEndTime:"",
                isOpenUpdate:false,
                updateEndTime:"",
                explain:"",
                impeachExplain:"",
                impeachTelephone:"",
                impeachMailBox:"",
                notification:[]
            },
            defaultExplain:`
                <p>(<span style="color: #e03e2d;">赛事名称</span>) 报名结束，现将参赛者名单公布如下。</p>
                <p>公示期内，欢迎各界监督与举报。监督与举报内容一经查实，组委会将通知球队进行整改，球队须在收到组委会正式通知（<span style="color: #e03e2d;">三</span>）日内，更换符合参赛资格要求的运动员进行二次报名。二次报名后，仍被审核出存在不符合参赛资格运动员的球队，不再获得其他补充报名的机会，相关运动员名额按照未报满处理。</p>
                <p>公示期结束后，在未收到详细举报材料的情况下，组委会不再受理关于运动员资格的举报。如仍收到详细举报材料，经组委会核查属实，组委会将正式通知所涉及球队，该队不得将违规球员作为运动员参赛，且该队不再获得补充报名运动员的机会。</p>
            `,
            defaultImpeachExplain:`
                <p>如果您认为运动员不符合参赛资格，请通过下面的方式向组委会举报。</p>
                <p>受理范围：举报符合下列条件的，予以受理。</p>
                <p>1.有明确的举报对象，需要明确指出是哪个组别的哪个代表队的哪个运动员。</p>
                <p>2.提供可查证的具体事实和证据。</p>
            `,
            rules:{
                publicEndTime:[
                    {required:true,
                    validator:(rule, value, callback)=>{
                        var dialog = this.dialog;
                        if(!value){
                            callback(new Error('请选择时间'));
                        }else if([1,3].includes(dialog.type)){
                            let time = new Date(value).getTime(),
                                beginTime = new Date().getTime(),
                                endTime = new Date(dialog.info.LeagueBeginTime).getTime();
                            if(beginTime > time){
                                callback(new Error('公示结束时间要晚于当前时间'));
                            }else if(endTime < time){
                                callback(new Error('公示结束时间要早于比赛开始时间'));
                            }else{
                                callback();
                            }
                        }else{
                            callback();
                        }
                    }}
                ],
                updateEndTime:[
                    {required:true,
                    validator:(rule, value, callback)=>{
                        var dialog = this.dialog,
                            form = this.form;
                        if(!value){
                            callback(new Error('请选择时间'));
                        }else if([1,2,3,4,5].includes(dialog.type)){
                            let time = new Date(value).getTime(),
                                beginTime = null,
                                endTime = new Date(dialog.info.LeagueBeginTime).getTime();
                            if([1,3].includes(dialog.type)){
                                beginTime = new Date(form.publicEndTime || new Date()).getTime();
                            }else if([2].includes(dialog.type)){
                                beginTime = new Date(dialog.info.PublicEndTime).getTime();
                            }else if([4,5].includes(dialog.type)){
                                beginTime = new Date().getTime();
                            }
                            if(beginTime > time){
                                callback(new Error('信息修改结束时间要晚于公示结束时间'));
                            }else if(endTime < time){
                                callback(new Error('信息修改结束时间要早于比赛开始时间'));
                            }else{
                                callback();
                            }
                        }else{
                            callback();
                        }
                    }}
                ],
                explain:[
                    {required:true,message:"请输入公示说明"}
                ],
                impeachExplain:[
                    {required:true,message:"请输入举报说明"}
                ],
                impeachTelephone:[
                    {required:true,message:"请输入举报电话"}
                ],
                impeachMailBox:[
                    {required:true,message:"请输入举报邮箱"}
                ],
                number: [{validator:(rule, value, callback)=>{
                        if(value && !/^[0-9]+$/.test(value)){
                            callback(new Error('请输入正确的数字'))
                        }else{
                            callback()
                        }
                    }
                }]
            },
            UploaderOptions:{},
            offlineShow: false,
            offlineForm: {
                subLeagueId: 0,
                teamCount: 0,
                memberCount: 0,
                doubleCount: 0
            }
        }
    },
    created(){
        this.getList();
    },
    watch:{
        status(){
            this.getList();
        },
        'form.isOpenUpdate':{
            deep:true,
            handler(newVal){
                if(!newVal){
                    this.form.updateEndTime = "";
                }
            }
        }
    },
    computed:{
        dialogTitle(){
            var title = "";
            if(this.dialog.type > 0){
                title = this.dialogTexts[this.dialog.type - 1].title;
            }
            if([1,4].includes(this.dialog.type) && this.dialog.info.PublicTimes > 1){
                title += `（第${numberToChinese(this.dialog.info.PublicTimes)}次）`;
            }else if([3].includes(this.dialog.type) && this.dialog.info.PublicTimes > 0){
                let num = this.dialog.info.PublicTimes
                    num += 1;
                title += `（第${numberToChinese(num)}次）`;
            }
            return title;
        },
        publicPickerOptions(){
            var dialog = this.dialog,
                beginDate = null,
                endDate = null;
            if([1,3].includes(dialog.type)){
                beginDate = new Date();
                endDate = dialog.info.LeagueBeginTime;
            }
            return {
                disabledDate(time){
                    return (beginDate ? time.getTime() < new Date(dateFormat(beginDate,'yyyy-MM-dd 00:00:00')).getTime() : false)
                        ||
                        (endDate ? time.getTime() > new Date(dateFormat(endDate,'yyyy-MM-dd 23:59:59')).getTime() : false);
                }
            }
        },
        updatePickerOptions(){
            var dialog = this.dialog,
                form = this.form,
                beginDate = null,
                endDate = null;
            if([1,3].includes(dialog.type)){
                beginDate = form.publicEndTime || new Date();
            }else if([2].includes(dialog.type)){
                beginDate = dialog.info.PublicEndTime;
            }else if([4,5].includes(dialog.type)){
                beginDate = new Date();
            }
            if([1,2,3,4,5].includes(dialog.type)){
                endDate = dialog.info.LeagueBeginTime;
            }
            return {
                disabledDate(time){
                    return (beginDate ? time.getTime() < new Date(dateFormat(beginDate,'yyyy-MM-dd 00:00:00')).getTime() : false)
                        ||
                        (endDate ? time.getTime() > new Date(dateFormat(endDate,'yyyy-MM-dd 23:59:59')).getTime() : false);
                }
            }
        }
    },
    methods:{
        getList(){
            this.loading = true;
            GetLeagueEnrollAuditList({
                ErollStatus: this.status
            }).then((data)=>{
                this.loading = false;
                if(data.Code == 0){
                    this.list = data.AuditList;
                }
            }).catch(() => {
                this.loading = false;
            })
        },
        updateOffline(info){
            this.offlineForm = {
                subLeagueId: info.SubLeagueId,
                teamCount: info.OfflineEnrollData?.TeamsCount || "",
                memberCount: info.OfflineEnrollData?.SinglesCount || "",
                doubleCount: info.OfflineEnrollData?.DoublesCount || ""
            }
            this.offlineShow = true;
        },
        submitOffline(){
            this.$refs['offlineForm'].validate((valid)=>{
                if(valid){
                    let form = this.offlineForm;
                    let loading = this.$loading({
                        lock: true,
                        text: "保存中",
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    let OfflineEnrollData = {
                        "TeamsCount": +(form.teamCount || 0),
                        "SinglesCount": +(form.memberCount || 0),
                        "DoublesCount": +(form.doubleCount || 0)
                    }
                    SaveLeagueOfflineEnrollData({
                        "SubLeagueId": form.subLeagueId,
                        "OfflineEnrollData": OfflineEnrollData
                    }).then(data=>{
                        loading.close();
                        if(data.Code == 0){
                            this.$message.success('修改成功');
                            this.offlineShow = false;
                            let index = this.list.findIndex(item=>item.SubLeagueId == this.offlineForm.subLeagueId);
                            if(index > -1){
                                this.$set(this.list[index], 'OfflineEnrollData', OfflineEnrollData);
                            }
                        }
                    }).catch(()=>{
                        loading.close();
                    })
                }
            })
        },
        openOption(type,info){
            var dialog = this.dialog,
                form = this.form;
            dialog.type = type;
            dialog.info = info || {};
            dialog.show = true;
            if(type == 1){
                form.publicEndTime = info.PublicEndTime;
            }
            if(type == 2){
                form.updateEndTime = info.UpdateEndTime;
            }
            if(type == 3){
                GetCreateLeagueEnrollInitInfo({
                    LeagueEnrollId: info.LeagueEnrollId
                }).then(data=>{
                    if(data.Code == 0){
                        this.UploaderOptions = data.UploadOption;
                    }
                })
                GetLeagueEnrollPublicSetting({
                    "SubLeagueId": info.SubLeagueId,
                    "QueryType": 3
                }).then(data=>{
                    if(data.Code == 0){
                        form.explain = data.PublicNotice || this.defaultExplain;
                        form.impeachExplain =  data.ImpeachNotice || this.defaultImpeachExplain;
                        form.impeachTelephone = data.ImpeachTelephone || "";
                        form.impeachMailBox = data.ImpeachMailBox || "";
                    }
                })
            }
            if([1, 4].includes(type) && info.UpdateEndTime){
                form.isOpenUpdate = true;
                form.updateEndTime = info.UpdateEndTime;
            }
        },
        submit(){
            this.$refs['form'].validate((valid)=>{
                if(valid){
                    let dialog = this.dialog,
                        form = this.form;
                    let loading = this.$loading({
                        lock: true,
                        text: "保存中",
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    (function(){
                        if(dialog.type == 1){//修改公示时间
                            return UpdateLeagueEnrollPublicTime({
                                "SubLeagueId": dialog.info.SubLeagueId,
                                "PublicBeginTime": null,
                                "PublicEndTime": form.publicEndTime,
                                "UpdateEndTime": form.updateEndTime || null
                            })
                        }else if(dialog.type == 2){//修改信息修改结束时间
                            return OpenLeagueEnrollEditTime({
                                "SubLeagueId": dialog.info.SubLeagueId,
                                "UpdateEndTime": form.updateEndTime
                            })
                        }else if(dialog.type == 3){//开始公示
                            return StartLeagueEnrollPublic({
                                "SubLeagueId": dialog.info.SubLeagueId,
                                "PublicBeginTime": null,
                                "PublicEndTime": form.publicEndTime,
                                "UpdateEndTime": form.updateEndTime || null,
                                "PublicNotice": form.explain,
                                "ImpeachTelephone": form.impeachTelephone,
                                "ImpeachMailBox": form.impeachMailBox,
                                "ImpeachNotice": form.impeachExplain
                            })
                        }else if(dialog.type == 4){//结束公示
                            return EndLeagueEnrollPublic({
                                "SubLeagueId": dialog.info.SubLeagueId,
                                "UpdateEndTime": form.updateEndTime || null
                            })
                        }else if(dialog.type == 5){//开放修改
                            return OpenLeagueEnrollEditTime({
                                "SubLeagueId": dialog.info.SubLeagueId,
                                "UpdateEndTime": form.updateEndTime
                            })
                        }else if(dialog.type == 6){//完成报名
                            let IsSendMsg = 0;
                            if(form.notification.length > 0){
                                IsSendMsg = 1;
                            }
                            return CompleteLeagueEnroll({
                                "SubLeagueId": dialog.info.SubLeagueId,
                                "IsSendMsg": IsSendMsg
                            })
                        }
                    }).apply(this).then(data=>{
                        loading.close();
                        if(data.Code == 0){
                            this.$message.success("操作成功");
                            this.dialog.show = false;
                            this.getList();
                        }
                    }).catch(()=>{
                        loading.close();
                    })
                }
            })
        },
        afreshSend(subLeagueId){
            SendLeaguEnrollResultNotice({
                "SubLeagueId": subLeagueId
            }).then(data=>{
                if(data.Code == 0){
                    this.$message.success("操作成功");
                    this.getList();
                }
            })
        },
        //导出名单
        exportList(subLeagueId, leagueName){
            let loading = this.$loading({
                    lock: true,
                    text: "正在导出名单",
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
            ExportLeagueEnrollTeamNameList({
                "SubLeagueId": subLeagueId
            }).then(data=>{
                loading.close();
                if(data.Code == 0){
                    var TeamExcelSheet = XLSX.utils.aoa_to_sheet(this.toArr(data.TeamExcelBase64)),
                        TeamMemberExcelSheet = XLSX.utils.aoa_to_sheet(this.toArr(data.TeamMemberExcelBase64)),
                        AllTeamUserExcelSheet = XLSX.utils.aoa_to_sheet(this.toArr(data.AllTeamUserExcelBase64));
                        TeamExcelSheet['!cols'] = [
                            {wpx: 80},
                            {wpx: 80},
                            {wpx: 100},
                            {wpx: 140},
                            {wpx: 100},
                        ];
                        TeamMemberExcelSheet['!cols'] = [
                            {wpx: 80},
                            {wpx: 80},
                            {wpx: 140},
                            {wpx: 100},
                            {wpx: 100},
                            {wpx: 80},
                            {wpx: 80},
                            {wpx: 100},
                            {wpx: 140},
                            {wpx: 50},
                            {wpx: 50},
                        ];
                        AllTeamUserExcelSheet['!cols'] = [
                            {wpx: 140},
                            {wpx: 100},
                            {wpx: 80},
                            {wpx: 100},
                            {wpx: 140},
                            {wpx: 50},
                            {wpx: 50},
                        ];
                    var blob = this.sheet2blob([TeamExcelSheet, TeamMemberExcelSheet, AllTeamUserExcelSheet]);
                    this.openDownloadXLSXDialog(blob, leagueName + '参赛者名单.xlsx');
                }
            }).catch(()=>{
                loading.close();
            })
        },
        toArr(dataBase64){
            var data = new Buffer(dataBase64, 'base64').toString('utf8');
            var arr = data.split(/\n/),
                arr2 = [];
            for(var i = 0;i<arr.length;i++){
                if(arr[i]){
                    arr2.push(arr[i].split(',').map(n=>JSON.parse(n)));
                }
            }
            return arr2;
        },
         //导出excel相关函数
        sheet2blob(sheets = [], sheetNames = []) {
            var workbook = {
                SheetNames: [],
                Sheets: {}
            };
            for(var i=0;i<sheets.length;i++){
                let sheetName = sheetNames[i] || ('sheet' + (i + 1));
                workbook.Sheets[sheetName] = sheets[i];
                workbook.SheetNames.push(sheetName);
            }
            // 生成excel的配置项
            var wopts = {
                bookType: 'xlsx', // 要生成的文件类型
                bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
                type: 'binary'
            };
            var wbout = XLSX.write(workbook, wopts);
            var blob = new Blob([s2ab(wbout)], {type:"application/octet-stream"});
            // 字符串转ArrayBuffer
            function s2ab(s) {
                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
            }
            return blob;
        },
        //导出肖像照片
        exportPhotograph(subLeagueId, leagueName){
            let loading = this.$loading({
                    lock: true,
                    text: "正在导出肖像照片",
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
            GetLeagueEnrollPortraitPhotoList({
                "SubLeagueId": subLeagueId
            }).then(data=>{
                if(data.Code == 0){
                    if(data.UserPortraitList && data.UserPortraitList.length > 0){
                        this.createZip(data.UserPortraitList).then(res=>{
                            loading.close();
                            this.openDownloadXLSXDialog(res, leagueName);
                        }).catch((name)=>{
                            loading.close();
                            this.$message.error(name + '肖像照片导出异常');
                        })
                    }else{
                        this.$message.warning('无肖像照片');
                        loading.close();
                    }
                }
            }).catch(()=>{
                loading.close();
            })
        },
        createZip(list){
            const zip = new JSZip();
            return new Promise((resolve,reject)=>{
                let num = 0, length = 0;
                list.forEach(item => {
                    let deputation = zip.folder(item.DeputationName ? (item.CompanyName ? `${item.CompanyName}-${item.DeputationName}` : item.DeputationName) : '个人运动员');
                    let userList = item.UserList;
                    userList.forEach(async userItem=>{
                        if(userItem.PortraitPhoto){
                            length++;
                            let name = userItem.Name;
                            if(userList.filter(n => n.Name == name).length > 1){
                                name += `-${userItem.Phone}`;
                            }
                            try{
                                let data = await this.imgLoad(userItem.PortraitPhoto, name);
                                let type = (data.type.split('/')[1] || 'jpg');
                                type = type == 'jpeg' ? 'jpg' : type;
                                deputation.file(name + '.' + type, data);
                                num++;
                                if(num == length){
                                    zip.generateAsync({ type: "blob" }).then((content) => {
                                        resolve(content);
                                    }).catch((err)=>{
                                        reject(err);
                                    });
                                }
                            }catch{
                                num++;
                                if(num == length){
                                    zip.generateAsync({ type: "blob" }).then((content) => {
                                        resolve(content);
                                    }).catch((err)=>{
                                        reject(err);
                                    });
                                }
                            }
                        }
                    })
                });
            })
        },
        imgLoad(url, name){
            return new Promise(function(resolve, reject){
                Promise.all([
                    new Promise((resolve, reject)=>{
                        let image = new Image();
                        image.src = url;
                        image.onerror = reject;
                        image.onload = resolve;
                    }),
                    new Promise((resolve, reject)=>{
                        try{
                            let xhr = new XMLHttpRequest();
                                xhr.open('get', url, true);
                                xhr.responseType = 'blob';
                                xhr.onload = function () {
                                    if(xhr.readyState == 4 && xhr.status == 200){
                                        resolve(xhr.response);
                                    }else{
                                        reject();
                                    }
                                }
                                xhr.send();
                        }catch{
                            reject();
                        }
                    })
                ]).then((data)=>{
                    resolve(data[1]);
                }).catch(()=>{
                    reject(name);
                })
            })
        },
        openDownloadXLSXDialog(url, saveName){
            if(typeof url == 'object' && url instanceof Blob){
                url = URL.createObjectURL(url); // 创建blob地址
            }
            var aLink = document.createElement('a');
            aLink.href = url;
            aLink.download = saveName || ''; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
            var event;
            if(window.MouseEvent) event = new MouseEvent('click');
            else{
                event = document.createEvent('MouseEvents');
                event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
            }
            aLink.dispatchEvent(event);
        }
    }
}
</script>
<style scoped>
    .statistics{
        display: flex;
    }
    .statistics .el-button{
        margin-left: 10px;
    }
    .statistics_title{
        display: flex;
        justify-content: space-between;
    }
    .offline .el-input ::v-deep input{
        text-align: right;
    }
</style>
<style>
    #EnrollAuditList .editor .tox-tinymce{
        height: 250px !important;
    }
    #EnrollAuditList .el-dialog__wrapper .el-dialog{
        max-width: 900px !important;
    }
</style>